import classes from "./index.module.scss"
import secondOnboard from "../../assets/images/onboard/secondOnboard.png"

const Second = () => {
  return(
    <div className={classes.insideContainer}>
      <img src={secondOnboard} alt="second onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Your startup</h2>
        <p className={classes.text}>Your startup is a subscription service for delivery of pet care packages. Business model: a monthly subscription for delivery of a box with all the necessary items.</p>
      </div>
    </div>
  )
}

export default Second

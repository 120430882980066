import {useEffect} from "react"

import classes from "./index.module.scss"
import fourthOnboard from "../../assets/images/onboard/fourthOnboard.png"
import {useActions} from "../../hooks/actions";

const Fourth = () => {
  const { handleStagesModal } = useActions()

  useEffect(() => {
    return () => handleStagesModal({open: true, name: "FFF"})
  }, [handleStagesModal])

  return(
    <div className={classes.insideContainer}>
      <img src={fourthOnboard} alt="fourth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>FFF</h2>
        <p className={classes.text}>At this very early stage, you rely on your personal relationships to obtain funding. You have nothing with which to entice investors, so you go to your FFFs: Friends, Fools, and Family. They will be your very first investors and give your startup $100,000!</p>
        <p className={classes.text}>Now let's see take a closer look on "FFF" stage details - <span className={classes.helperText}>click on “FFF” button in “Startup Stages” section</span></p>
      </div>
    </div>
  )
}

export default Fourth

import {useSelector} from "react-redux"

import classes from "./index.module.scss"
import eighteenthOnboard from "../../assets/images/onboard/firstOnboard.png"
import {StartupStages} from "../../constants/index"

const Eighteenth = () => {
  const { data } = useSelector(state => state.game)

  if(!data) {
    return
  }

  const { priority, round_task } = data.current_round
  const roundName = StartupStages.eng.fields[priority - 1]

  return(
    <div className={classes.insideContainer}>
      <img src={eighteenthOnboard} alt="eighteenth onboard" height="207px" width="207px"/>
      <div className={`${classes.textDataContainer} ${classes.fullWidth}`}>
        <h2 className={`${classes.header} ${classes.textCenter}`}>Congratulations! <br/> You’ve made to it {roundName} round</h2>
        <p className={`${classes.text} ${classes.textStart}`}>
          <span className={classes.preText}>Your next goal</span>
          <br/>
          {round_task}
        </p>
      </div>
    </div>
  )
}

export default Eighteenth

import classes from "./index.module.scss"
import sixteenthOnboard from "../../assets/images/onboard/firstOnboard.png"

const Sixteenth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={sixteenthOnboard} alt="sixteenth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Well done! Task complete</h2>
        <p className={classes.text}>The likelihood of bugs decreases as your company grows through the stages; you’ll attract mentors and investors who will bring knowledge and expertise to your business. <br/>
        Hiring new employees, however, increases the probability of making a mistake as the new hires are not yet experienced with working on your team.</p>
      </div>
    </div>
  )
}

export default Sixteenth

import classes from "./index.module.scss"
import eleventhOnboard from "../../assets/images/onboard/eleventhOnboard.png"

const Eleventh = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={eleventhOnboard} alt="eleventh onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Turn completed</h2>
        <p className={classes.text}>Congratulations! You’ve increased the number of your subscribers. Keep completing tasks that bring you closer to your goal. Now try working with other metrics.</p>
      </div>
    </div>
  )
}

export default Eleventh

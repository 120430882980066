import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {URL} from "../constants";

export const Api = axios.create({
   withCredentials: true,
   baseURL: URL
})

export const fetchStartGame = createAsyncThunk(
"game/start", async () => {
    const resp = await Api.post("game/start", {})
    return resp
});

export const fetchGameInfo = createAsyncThunk(
"game/info",async () => {
    const resp = await Api.get("game/info")
    return resp
});

export const fetchBrainstorm = createAsyncThunk(
"game/brainstorm",async (body) => {
  const resp = await Api.post("game/brainstorm", body)
  return resp
})

export const fetchTurn = createAsyncThunk(
"game/turn",async (body) => {
  const resp = await Api.post("game/turn", body)
  return resp
})

export const fetchFinishGame = createAsyncThunk(
"game/finish-game",async (body) => {
  const resp = await Api.post("game/finish-game", body)
  return resp
})

export const hireInvestor = createAsyncThunk(
"game/hireInvestor",async (body) => {
  const resp = await Api.post("game/hire-investor", body)
  return resp
})

export const hireMentor = createAsyncThunk(
"game/hireMentor",async (body) => {
  const resp = await Api.post("game/hire-mentor", body)
  return resp
})

export const buyoutInvestor = createAsyncThunk(
"game/buyout-investor",async (body) => {
  const resp = await Api.post("game/buyout-investor", body)
  return resp
})

export const onboarding = createAsyncThunk(
"game/onboarding",async (body) => {
  const resp = await Api.post("game/onboarding", body)
  return resp
})

export const endOnboarding = createAsyncThunk(
"game/end-onboarding",async (body) => {
  const resp = await Api.post("game/end-onboarding", body)
  return resp
})

export const fetchRating = createAsyncThunk(
"game/rating",async () => {
  const resp = await Api.get("game/rating")
  return resp
})

export const fetchModules = createAsyncThunk(
  "education/modules", async () => {
    const resp = await Api.get("education/modules")
    return resp
  }
)

export const fetchEndArticle = createAsyncThunk(
  "education/end-article", async (body) => {
    const resp = await Api.post("education/article", body)
    return resp
  }
)

export const fetchArticle = createAsyncThunk(
  "education/article", async (id) => {
    const resp = await Api.get(`education/article/${id}`)
    return resp
  }
)

export const fetchSubscribe = createAsyncThunk(
  "payments/subscribe", async () => {
    const resp = await Api.get("payments/subscribe")
    return resp
  }
)

export const fetchLifetimeAccess = createAsyncThunk(
  "payments/lifetimeAccess", async (body) => {
    const resp = await Api.post("payments/lifetimeAccess", body)
    return resp
  }
)
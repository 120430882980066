import sixthOnboard from "../../assets/images/onboard/sixthOnboard.png"
import classes from "./index.module.scss"

const Sixth = ({isMobile}) => {
  return(
    <div className={classes.insideContainer}>
      <img src={sixthOnboard} alt="sixth onboard" width="207px" height="207px" className={classes.absoluteImage}/>
      <div className={`${classes.textDataContainer} ${isMobile ? "" : classes.withMarginLeft}`}>
        <h2 className={classes.header}>Tasks</h2>
        <p className={classes.text}>Using all available resources, perform the necessary tasks that will lead you to your goal.</p>
      </div>
    </div>
  )
}

export default Sixth

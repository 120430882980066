import classes from "./index.module.scss"
import thirdOnboard from "../../assets/images/onboard/thirdOnboard.png"

const Third = () => {
  return(
    <div className={classes.insideContainer}>
      <img src={thirdOnboard} alt="third onboard" width="122px" height="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Stages</h2>
        <p className={classes.text}>The life cycle of a startup includes different stages of business development, each one with its name and unique set of goals. Your tasks in the simulator will correspond to these stages.</p>
      </div>
    </div>
  )
}

export default Third

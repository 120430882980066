import classes from "./index.module.scss"
import seventhOnboard from "../../assets/images/onboard/seventhOnboard.png"

const Seventh = () => {
  return(
    <div className={classes.insideContainer}>
      <img src={seventhOnboard} alt="seventh onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Financials</h2>
        <p className={classes.text}>Your real-time financial situation is displayed in the side menu and lower right corner of the screen. You can complete tasks as long as you have cash available.</p>
      </div>
    </div>
  )
}

export default Seventh

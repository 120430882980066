import classes from "./index.module.scss"
import tenthOnboard from "../../assets/images/onboard/tenthOnboard.png"

const Tenth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={tenthOnboard} alt="tenth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Making a turn</h2>
        <p className={classes.text}>In order to make a turn, select the task you wish to complete and <span className={classes.helperText}>hit the "Make a turn" button.</span> Keep in mind that you need to keep track of which tasks you completed or didn’t complete in previous turns. Upon  completing a turn, you’ll receive payment from customers, pay salaries, and make progress on active tasks.</p>
      </div>
    </div>
  )
}

export default Tenth

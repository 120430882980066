import {useActions} from "../../hooks/actions"
import seventeenthOnboard from "../../assets/images/onboard/seventeenthOnboard.png"
import classes from "./index.module.scss"

const Seventeenth = ({isMobile}) => {

  const { closeOnboard } = useActions()

  return(
    <div className={classes.insideContainer}>
      <img src={seventeenthOnboard} alt="seventeenth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={`${classes.header} ${classes.textCenter}`}>It looks like you’re <br/> running out of money.</h2>
        <p className={classes.text}>Each round will bring with it new investors. That said, investors can be pretty demanding when it comes to founders’ share distribution, so if in the earlier stages you attracted investors with inaccurate information, they may now refuse you.</p>
        <p className={classes.text}>Let’s see which of the investors are ready to invest in your startup - <span className={classes.helperText}>сlick on “Investors”</span>.</p>
      </div>
      {isMobile && <button className={classes.nextButton} onClick={() => closeOnboard(true)}>
        Try it
      </button>}
    </div>
  )
}

export default Seventeenth

import {useActions} from "../../hooks/actions"
import twentyFirstOnboard from "../../assets/images/onboard/twentyFirstOnboard.png"
import classes from "./index.module.scss"

const TwentyFirst = ({isMobile}) => {

  const { closeOnboard } = useActions()

  return(
    <div className={classes.insideContainer}>
      <img src={twentyFirstOnboard} alt="twenty first onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Limited resources</h2>
        <p className={classes.text}>Whoops! It looks like you don’t have the necessary team members to complete at least one of the available tasks. <span className={classes.helperText}>{isMobile ? "Click on “Team management”" :"Click on “Edit team”"}</span> to hire employees.</p>
      </div>
      {isMobile && <button className={classes.nextButton} onClick={() => closeOnboard(true)}>
        Try it
      </button>}
    </div>
  )
}

export default TwentyFirst

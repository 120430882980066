import {useDispatch} from "react-redux";
import {
  fetchStartGame,
  fetchGameInfo,
  fetchBrainstorm,
  fetchTurn,
  hireMentor,
  hireInvestor,
  buyoutInvestor,
  onboarding,
  endOnboarding,
  fetchFinishGame,
  fetchRating,
  fetchModules,
  fetchEndArticle,
  fetchArticle,
  fetchLifetimeAccess,
  fetchSubscribe
} from "../services";
import {bindActionCreators} from "@reduxjs/toolkit";
import {gameActions} from "../store/game/gameSlice";
import {onboardActions} from "../store/onboard/onboardSlice";
import {platformActions} from "../store/platform/platformSlice";

const actions = {
  fetchStartGame,
  fetchGameInfo,
  fetchBrainstorm,
  fetchTurn,
  hireMentor,
  hireInvestor,
  buyoutInvestor,
  onboarding,
  endOnboarding,
  fetchFinishGame,
  fetchRating,
  fetchModules,
  fetchEndArticle,
  fetchArticle,
  fetchLifetimeAccess,
  fetchSubscribe,
  ...gameActions,
  ...onboardActions,
  ...platformActions
}

export const useActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}

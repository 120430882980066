import classes from "./index.module.scss"
import firstOnboard from "../../assets/images/onboard/firstOnboard.png"

const First = () => {
  return(
    <div className={classes.insideContainer} id="one">
      <img src={firstOnboard} alt="first onboard" id="two" height="207px" width="207px"/>
      <div className={classes.textDataContainer} id="three">
        <h2 className={classes.header}>Hey there!</h2>
        <p className={classes.text}>Welcome to TeachMeGrow. The objective of the simulator is to develop a company from the idea stage and its first sale, all the way to the IPO. On your way to achieving this goal, you’ll learn more about unit economics and gain the tools and practices necessary for building a successful venture capital business.</p>
      </div>
    </div>
  )
}

export default First

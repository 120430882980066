import {useEffect} from "react"

import fifthOnboard from "../../assets/images/onboard/fifthOnboard.png"
import {useActions} from "../../hooks/actions"
import classes from "./index.module.scss"

const Fifth = ({isMobile}) => {
  const { handleStagesModal } = useActions()

  useEffect(() => {
    return () => handleStagesModal()
  }, [handleStagesModal])

  return(
    <div className={classes.insideContainer}>
      <img src={fifthOnboard} alt="fifth onboard" height="207px" width="207px" className={classes.absoluteImage}/>
      <div className={`${classes.textDataContainer} ${isMobile ? "" : classes.withMarginLeft}`}>
        <h2 className={classes.header}>Your goal</h2>
        <p className={classes.text}>Get your first ten subscribers and prove that your company is providing a valuable service and solving someone’s problem.</p>
      </div>
    </div>
  )
}

export default Fifth

import classes from "./index.module.scss"
import nineteenthOnboard from "../../assets/images/onboard/nineteenthOnboard.png"

const Nineteenth = ({isMobile}) => {
  return(
    <div className={classes.insideContainer}>
      <img src={nineteenthOnboard} alt="nineteenth onboard" height="207px" width="207px" className={classes.absoluteImage}/>
      <div className={`${classes.textDataContainer} ${isMobile ? "" : classes.withMarginLeft}`}>
        <h2 className={classes.header}>Metrics are related</h2>
        <p className={classes.text}>Remember that some unit economics metrics are interconnected. Take UA (influx of new subscribers), and CPA (cost of attracting one new subscriber), for example. As your user base grows, the cost of attracting new clients also increases. As these metrics are directly linked, a change in one affects the other. Some metrics are only linked unilaterally. That means that a change in AOV (the price of a product or service), for instance, will affect C1 (conversion rate), but the opposite isn’t true.</p>
      </div>
    </div>
  )
}

export default Nineteenth

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {URL} from "../../constants/index"

export const loginApi = createApi({
  reducerPath: "login/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}auth/`,
    credentials: "include",
  }),
  endpoints: build => ({
    register: build.mutation({
      query: (body) => ({
        url: "register/local",
        method: "POST",
        body
      })
    }),
    login: build.mutation({
      query: (body) => ({
        url: "login/local",
        method: "POST",
        body
      })
    }),
    logout: build.mutation({
      query: () => ({
        url: "logout",
        method: "GET"
      })
    }),
    check: build.mutation({
      query: (body) => ({
        url: "check-email",
        method: "POST",
        body
      })
    })
  })
})

export const { useLoginMutation, useRegisterMutation, useLogoutMutation, useCheckMutation } = loginApi

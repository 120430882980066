import { TourProvider } from '@reactour/tour'
import {useSelector} from "react-redux"
import { ToastContainer, Zoom } from 'react-toastify'
import { CookiesProvider } from "react-cookie"
import {
  RouterProvider,
} from "react-router-dom"
import { Suspense } from "react"

import {steps} from "./constants/steps"
import ContentComponent from "./components/Onboarding/content"
import router from "./router"
import useResize from "./hooks/useResize"
import Loader from "./components/Modal/loader";

function App() {
  const { onboardStep } = useSelector(state => state.onboard)
  const { width } = useResize()

  const renderLoader = () => <Loader open={true}/>;

  return (
    <TourProvider
      steps={steps(width <= 1100)}
      showCloseButton={false}
      showBadge={false}
      currentStep={onboardStep}
      ContentComponent={ContentComponent}
      styles={{
        maskWrapper: (base) => ({
          ...base,
          color: "rgba(93, 145, 251, 0.34)",
          opacity: 1,
          pointerEvents: "all",
          maxWidth: "100%",
       }),
        clickArea: () => ({
          borderRadius: "15px",
          backgroundColor: "white",
        }),
        popover: (base) => ({
          ...base,
          backgroundColor: "#FFFFFF",
          borderRadius: "15px",
          maxWidth: "80%",
          padding: width > 1100 ? "30px" : "16px",
          zIndex: onboardStep === 8 ? "999999" : "100000"
        }),
        maskArea: (base) => ({
          ...base,
          height: !!base.x ? base.height : 0,
          width: !!base.x ? base.width : 0,
          rx: "15px"
        }),
      }}
    >
      <CookiesProvider>
        <Suspense fallback={renderLoader()}>
          <RouterProvider router={router()} />
        </Suspense>
        <ToastContainer autoClose={1000} hideProgressBar transition={Zoom}/>
      </CookiesProvider>
    </TourProvider>
  );
}

export default App;

import classes from "./index.module.scss"
import fifteenthOnboard from "../../assets/images/onboard/fifteenthOnboard.png"

const Fifteenth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={fifteenthOnboard} alt="fifteenth onboard" height="207px" width="350px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Bugs</h2>
        <p className={classes.text}>Just like in real life, you’ll make mistakes which will cause you to fail in completing a task. It happens more frequently to a company in its earlier stages of growth, and It may take several tries to complete a task. When you encounter an error the simulator will automatically generate a task designed to minimize future mistakes (“bugs”). These tasks always require one turn to complete, do not affect your metrics, but reduce the change of getting an error by 1%.</p>
      </div>
    </div>
  )
}

export default Fifteenth

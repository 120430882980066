import classes from "./index.module.scss"
import thirteenthOnboard from "../../assets/images/onboard/thirteenthOnboard.png"

const Thirteenth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={thirteenthOnboard} alt="thirteenth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Brainstorm</h2>
        <p className={classes.text}>If ever run out of tasks, you can brainstorm with your team and generate more.</p>
      </div>
    </div>
  )
}

export default Thirteenth

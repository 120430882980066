import classes from "./index.module.scss"
import fourteenthOnboard from "../../assets/images/onboard/firstOnboard.png"

const Fourteenth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={fourteenthOnboard} alt="fourteenth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Let’s grow</h2>
        <p className={classes.text}>Take your company to the preSeed stage by completing the available tasks.</p>
      </div>
    </div>
  )
}

export default Fourteenth

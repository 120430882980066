import {createSlice} from "@reduxjs/toolkit";
import {fetchLifetimeAccess, fetchSubscribe} from "../../services";

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    loading: false,
    error: false,
    clientSecret: null,
    stripeOpen: false
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscribe.pending, (state) => {
      state.loading = true
      state.error = null
    }).addCase(fetchSubscribe.fulfilled, (state, {payload}) => {
      state.loading = false
      state.clientSecret = payload?.data?.clientSecret
    }).addCase(fetchSubscribe.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    }).addCase(fetchLifetimeAccess.pending, (state) => {
      state.loading = true
      state.error = null
    }).addCase(fetchLifetimeAccess.fulfilled, (state, {payload}) => {
      state.loading = false
    }).addCase(fetchLifetimeAccess.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const paymentsActions = paymentsSlice.actions
export const paymentsReducer = paymentsSlice.reducer

import {
  First,
  Second,
  Third,
  Fourth,
  Fifth,
  Sixth,
  Seventh,
  Eighth,
  Ninth,
  Tenth,
  Eleventh,
  Twelfth,
  Thirteenth,
  Fourteenth,
  Fifteenth,
  Sixteenth,
  Seventeenth,
  Eighteenth,
  Nineteenth,
  Twentieth,
  TwentyFirst
} from "../components/Onboarding"

export const steps = (isMobile) => [{
  content: <First/>,
  position: "center",
  disableActions: true
}, {
  selector: "#second-step",
  content: <Second/>,
  position: "center",
  disableActions: true
}, {
  selector: "#third-step",
  content: <Third/>,
  position: isMobile ? "center" : "bottom",
  disableActions: true
}, {
  selector: "#fourth-step",
  content: <Fourth/>,
  position: isMobile ? "center" : "bottom",
  disableActions: true
}, {
  selector: "#fifth-step",
  content: <Fifth isMobile={isMobile}/>,
  position: isMobile ? "top" : "bottom",
  disableActions: true
}, {
  selector: "#sixth-step",
  content: <Sixth isMobile={isMobile}/>,
  position: (props) => isMobile ? "top" : [30, props.windowHeight - props.height - 30],
  disableActions: true
}, {
  selector: "#seventh-step",
  content: <Seventh/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 350] : "right",
  disableActions: true
}, {
  selector: "#eighth-step",
  content: <Eighth isMobile={isMobile}/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 350] : "bottom",
  disableActions: true
}, {
  selector: "#ninth-step",
  content: <Ninth isMobile={isMobile}/>,
  position: isMobile ? "top" : "bottom",
  disableActions: true
}, {
  selector: "#tenth-step",
  content: <Tenth/>,
  position: "top",
  disableActions: true
}, {
  selector: "#eleventh-step",
  content: <Eleventh/>,
  position: isMobile ? "center" : "bottom",
  disableActions: true
}, {
  selector: "#twelfth-step",
  content: <Twelfth/>,
  position: isMobile ? "center" : "bottom",
  disableActions: true
}, {
  selector: "#thirteenth-step",
  content: <Thirteenth/>,
  position: "left",
  disableActions: true
}, {
  content: <Fourteenth/>,
  position: "center",
  disableActions: true
}, {
  selector: "#fifteenth-step",
  content: <Fifteenth/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 70] : "left",
  disableActions: true
}, {
  content: <Sixteenth/>,
  position: "center",
  disableActions: true
}, {
  selector: "#seventeenth-step",
  content: <Seventeenth isMobile={isMobile}/>,
  position: isMobile ? "bottom" : "center",
  disableActions: true
}, {
  selector: "#fourth-step",
  content: <Eighteenth/>,
  position: isMobile ? "center" : "bottom",
  disableActions: true
}, {
  selector: "#eighth-step",
  content: <Nineteenth isMobile={isMobile}/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 320] : "bottom",
  disableActions: true
}, {
  selector: "#twentieth-step",
  content: <Twentieth/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 350] : "right",
  disableActions: true
}, {
  selector: "#twentyFirst-step",
  content: <TwentyFirst isMobile={isMobile}/>,
  position: (props) => isMobile ? [(props.windowWidth - props.width) / 2, 350] : "right",
  disableActions: true
}]

import {createSlice} from "@reduxjs/toolkit";

const onboardSlice = createSlice({
  name: "onboard",
  initialState: {
    onboardOpen: localStorage.getItem("onboardOpen") || false,
    skipped: localStorage.getItem("skipped") || false,
    ended: localStorage.getItem("ended") || false,
    notEnougtMoneyShowed: localStorage.getItem("notEnougtMoneyShowed") || false,
    metricsShowed: localStorage.getItem("metricsShowed") || false,
    teamShowed: localStorage.getItem("teamShowed") || false,
    onboardStep: localStorage.getItem("onboardStep") ? parseInt(localStorage.getItem("onboardStep")) : 0,
    stagesModalOpen: localStorage.getItem("stagesModalOpen") || false,
    stagesModalName: localStorage.getItem("stagesModalName") || false
  },
  reducers: {
    handleStagesModal(state, { payload }) {
      state.stagesModalOpen = payload?.open
      state.stagesModalName = payload?.name
      payload ? localStorage.setItem("stagesModalOpen", "true") : localStorage.removeItem("stagesModalOpen")
      payload ? localStorage.setItem("stagesModalName", payload.name) : localStorage.removeItem("stagesModalName")
    },
    goNextStep(state) {
      state.onboardStep += 1
      localStorage.setItem("onboardStep", state.onboardStep)
      if (state.onboardStep === 18) {
        state.metricsShowed = true
        localStorage.setItem("metricsShowed", "true")
      }
    },
    goToNotEnougtMoney(state) {
      state.onboardOpen = true
      state.onboardStep = 16
      state.notEnougtMoneyShowed = true
      localStorage.setItem("onboardOpen", "true")
      localStorage.setItem("onboardStep", "16")
      localStorage.setItem("notEnougtMoneyShowed", "true")
    },
    goToStep(state, {payload}) {
      state.onboardOpen = true
      state.onboardStep = payload
      localStorage.setItem("onboardOpen", "true")
      localStorage.setItem("onboardStep", payload)
    },
    startOnboard(state) {
      state.onboardOpen = true
      state.onboardStep = 0
      localStorage.setItem("onboardOpen", "true")
      localStorage.setItem("onboardStep", 0)
      state.skipped = false
      state.ended = false
      state.bugsOpened = false
      state.bugsTaskOpened = false
      state.investorsOpened = false
      state.competitorsOpened = false
    },
    openOnboard(state) {
      state.onboardOpen = true
      localStorage.setItem("onboardOpen", "true")
      if(localStorage.getItem("onboardStep")){
        state.onboardStep = parseInt(localStorage.getItem("onboardStep"))
      }
      state.skipped = false
      state.ended = false
      state.bugsOpened = false
      state.bugsTaskOpened = false
      state.investorsOpened = false
      state.competitorsOpened = false
    },
    closeOnboard(state) {
      if (state.onboardStep === 20) {
        state.teamShowed = true
        localStorage.setItem("teamShowed", "true")
      }
      state.onboardOpen = false
      state.onboardStep = 0
      localStorage.removeItem("onboardOpen")
      localStorage.removeItem("onboardStep")
    },
    endOnboard(state) {
      state.onboardOpen = false
      state.onboardStep = 0
      state.ended = true
      localStorage.removeItem("onboardOpen")
      localStorage.removeItem("onboardStep")
      localStorage.setItem("ended", "true")
    },
    skipOnboard(state) {
      state.onboardOpen = false
      state.onboardStep = 0
      state.skipped = true
      localStorage.removeItem("onboardOpen")
      localStorage.removeItem("onboardStep")
      localStorage.setItem("skipped", "true")
    }
  }
})

export const onboardActions = onboardSlice.actions
export const onboardReducer = onboardSlice.reducer

import {createSlice} from "@reduxjs/toolkit";
import {
  fetchBrainstorm,
  fetchGameInfo,
  fetchStartGame,
  fetchTurn,
  hireInvestor,
  hireMentor,
  buyoutInvestor,
  onboarding,
  endOnboarding,
  fetchFinishGame, fetchRating
} from "../../services";

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    data: null,
    ratings: null,
    error: null,
    success: false,
    loading: false,
    loginOpened: false,
    ratingsOpened: false,
    finished: false,
    finishData: null,
  },
  reducers: {
    addToInProgress(state, { payload }) {
      state.data.active_tasks.push(payload)
      localStorage.setItem("activeTasks", JSON.stringify(state.data.active_tasks))
    },
    removeFromInProgress(state, { payload }) {
      state.data.active_tasks = state.data.active_tasks.filter(f => f.id !== payload.id)
      localStorage.setItem("activeTasks", JSON.stringify(state.data.active_tasks))
    },
    openLogin(state) {
      state.loginOpened = true
    },
    finish(state) {
      state.finished = true
    },
    closeLogin(state) {
      state.loginOpened = false
    },
    openRatings(state) {
      state.ratingsOpened = true
    },
    closeRatings(state) {
      state.ratingsOpened = false
    },
    deleteData(state) {
      state.data = null
    },
    setFinishData(state, { payload }) {
      state.finishData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGameInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchGameInfo.fulfilled, (state, {payload}) => {
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      const newNotifications = notifications[notifications.length - 1]?.header === payload.data.notifications[0]?.header ?
        [...notifications] : [...notifications, ...payload.data.notifications]
      const activeTasks = JSON.parse(localStorage.getItem("activeTasks")) || payload.data.all_tasks.filter(t => t.is_active === true)
      state.data = state.data ?
        {...state.data,
          active_tasks: activeTasks,
          notifications: newNotifications,
        } : {
          ...payload.data,
          active_tasks: activeTasks,
          notifications: newNotifications,
        }
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(fetchGameInfo.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(fetchStartGame.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchStartGame.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      localStorage.removeItem("notifications")
      state.data = {
        ...payload.data,
        active_tasks: payload.data.all_tasks.filter(t => t.is_active === true),
        my_future_employees: []
      }
      localStorage.setItem("activeTasks", JSON.stringify(state.data.active_tasks))
      localStorage.setItem("notifications", JSON.stringify(payload.data.notifications))
      state.loading = false
      state.success = true
      state.finished = false
      state.ratings = null
      state.finishData = null

      localStorage.removeItem("onboardOpen")
      localStorage.removeItem("skipped")
      localStorage.removeItem("ended")
      localStorage.removeItem("onboardStep")
      localStorage.removeItem("stagesModalOpen")
      localStorage.removeItem("notEnougtMoneyShowed")
      localStorage.removeItem("metricsShowed")
    })
    .addCase(fetchStartGame.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(fetchBrainstorm.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchBrainstorm.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        notifications: [...notifications, ...payload.data.notifications],
      }
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(fetchBrainstorm.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(fetchTurn.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchTurn.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        notifications: [...notifications, ...payload.data.notifications],
        active_tasks: payload.data.all_tasks.filter(t => t.is_active === true),
        my_future_employees: []
      }
      localStorage.setItem("activeTasks", JSON.stringify(state.data.active_tasks))
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(fetchTurn.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(hireInvestor.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(hireInvestor.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        notifications: [...notifications, ...payload.data.notifications],
      }
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(hireInvestor.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(hireMentor.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(hireMentor.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        notifications: [...notifications, ...payload.data.notifications],
      }
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(hireMentor.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(buyoutInvestor.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(buyoutInvestor.fulfilled, (state, {payload}) => {
      localStorage.removeItem("my_future_employees")
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        notifications: [...notifications, ...payload.data.notifications],
      }
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(buyoutInvestor.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(onboarding.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(onboarding.fulfilled, (state, {payload}) => {
      const notifications = JSON.parse(localStorage.getItem("notifications")) || []
      state.data = {
        ...state.data,
        ...payload.data,
        active_tasks: payload.data.all_tasks.filter(t => t.is_active === true),
        notifications: [...notifications, ...payload.data.notifications],
      }
      localStorage.setItem("activeTasks", JSON.stringify(state.data.active_tasks))
      localStorage.setItem("notifications", JSON.stringify(state.data.notifications))
      state.loading = false
      state.success = true
    })
    .addCase(onboarding.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(endOnboarding.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(endOnboarding.fulfilled, (state) => {
      state.loading = false
      state.success = true
    })
    .addCase(endOnboarding.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(fetchFinishGame.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchFinishGame.fulfilled, (state) => {
      state.loading = false
      state.success = true
    })
    .addCase(fetchFinishGame.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    .addCase(fetchRating.pending, (state) => {
      state.loading = true
      state.error = null
    })
    .addCase(fetchRating.fulfilled, (state, {payload}) => {
      state.ratings = payload.data
      state.loading = false
      state.success = true
    })
    .addCase(fetchRating.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const gameActions = gameSlice.actions
export const gameReducer = gameSlice.reducer

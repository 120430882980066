import Modal from "./index";
import classes from "./index.module.scss"
import {ThreeDots} from "react-loader-spinner";

const Loader = ({ open }) => {
  return (
    <Modal open={open} setOpen={() => null} className={classes.loader} classNameOverlay={classes.loaderOverlay}>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#5d91fb"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Modal>
  )
}

export default Loader

import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {loginApi} from "./login/login.api";
import {gameReducer} from "./game/gameSlice";
import {onboardReducer} from "./onboard/onboardSlice";
import {paymentsReducer} from "./payments/paymentsSlice";
import {platformReducer} from "./platform/platformSlice";

export const rootReducer = combineReducers({
  game: gameReducer,
  onboard: onboardReducer,
  platform: platformReducer,
  payments: paymentsReducer,
  [loginApi.reducerPath]: loginApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }).concat([loginApi.middleware]))
})

import {useSelector} from "react-redux";

import arrowRight from "../../assets/images/icons/general/arrowRight.svg"
import rocket from "../../assets/images/icons/general/rocket.svg"
import useResize from "../../hooks/useResize"
import {useActions} from "../../hooks/actions"
import classes from "./index.module.scss"

const ContentComponent = (props) => {
  const { steps } = props

  const { goNextStep, skipOnboard } = useActions()

  const { onboardStep, metricsShowed } = useSelector(state => state.onboard)
  const { width } = useResize()

  const onboardStepInt = parseInt(onboardStep)

  const skippedSteps = [10, 13, 14, 15, 18, 19]

  const goNext = () => {
    if(skippedSteps.includes(onboardStepInt) || (onboardStepInt === 17 && metricsShowed)) {
      return skipOnboard()
    }
    return goNextStep()
  }

  const horizontalContainers = [4, 5, 8, 18]
  const withRocketIcon = [10, 13, 17]
  const withoutButtons = [3, 7, 8, 9, 16, 20]
  const withIconsMobile = [10, 13, 17]
  const buttonName = {
    10: "Let’s go!",
    11: "Next",
    12: "Got it",
    13: "Let’s Grow!",
    14: "Got it",
    15: "OK",
    17: "Let’s Grow!",
    18: "Got it",
    19: "Got it",
  }

  const withBr = [14,15,16,19,20]

  return (
    <div className={horizontalContainers.includes(onboardStepInt) ? classes.contentContainerHorizontal : classes.contentContainer}>
      {onboardStepInt < 11 && <span className={classes.badge}>{`${onboardStepInt + 1}/11`}</span>}
      {onboardStepInt > 10 && onboardStepInt < 14 && <span className={classes.badge}>{`${onboardStepInt - 10}/3`}</span>}
      {onboardStepInt > 16 && onboardStepInt < 19 && <span className={classes.badge}>{`${onboardStepInt - 16}/2`}</span>}
      {withBr.includes(onboardStepInt) && width <= 1100 && <br/>}
      {steps[onboardStepInt].content}
      {!withoutButtons.includes(onboardStepInt) && <div className={classes.buttonContainer}>
        {onboardStepInt === 0 && <button className={classes.skipButton} onClick={skipOnboard}>
          Skip tutorial
        </button>}
        <div className={classes.nextButtonBlock}>
          <button className={classes.nextButton} onClick={goNext}>
            <span>{buttonName[onboardStepInt] || "Next"}</span>
            {(width  > 1100 || withIconsMobile.includes(onboardStepInt)) && <img src={withRocketIcon.includes(onboardStepInt) ? rocket : arrowRight} alt="NextIcon"/>}
          </button>
        </div>
      </div>}
    </div>
  )
}

export default ContentComponent

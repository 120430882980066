import classes from "./index.module.scss"
import eighthOnboard from "../../assets/images/onboard/eighthOnboard.png"

const Eighth = ({isMobile}) => {
  return(
    <div className={classes.insideContainer}>
      <img src={eighthOnboard} alt="eighth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Unit-economics</h2>
        <p className={classes.text}>You can find your startup’s real-time unit economics displayed above the tasks you’re working on at any given time.
          <br/> <span className={classes.helperText}>{isMobile ? "Click on info button inside metrics panel" : "Click on any metric"}</span> to see its detailed description.
        </p>
      </div>
    </div>
  )
}

export default Eighth

import classes from "./index.module.scss"
import twentiethOnboard from "../../assets/images/onboard/twentiethOnboard.png"

const Twentieth = () => {
  return(
    <div className={classes.insideContainer}>
      <img src={twentiethOnboard} alt="twentieth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>New competitors have <br/> entered the market</h2>
        <p className={classes.text}>Here you can find useful information about the market, your competitors, and the market share distribution in your business. When a competitor obtains a share of the market, some potential users become unavailable to you.</p>
      </div>
    </div>
  )
}

export default Twentieth

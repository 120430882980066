import { lazy } from "react";
import {Route, createBrowserRouter, createRoutesFromElements} from "react-router-dom";

// import {useActions} from "./hooks/actions";
const Layout = lazy(() => import("./components/Layout"));
// const LayoutPlatform = lazy(() => import("./components/LayoutPlatform"));
const Main = lazy(() => import("./pages/main"));
const PrivacyPolicy = lazy(() => import("./pages/privacy"));
// const ModuleList = lazy(() => import("./pages/moduleList"));
// const ModuleItem = lazy(() => import("./pages/moduleItem"));

export default function Router() {
  // const { fetchModules, fetchArticle } = useActions()

  return createBrowserRouter(createRoutesFromElements(
      <Route>
          <Route path="/" element={<Layout />}>
              <Route index element={<Main />} />
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
2
        {/*<Route*/}
        {/*  path="/platform"*/}
        {/*  element={<LayoutPlatform/>}*/}
        {/*  loader={() => {*/}
        {/*    return fetchModules();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Route*/}
        {/*    index*/}
        {/*    element={<ModuleList/>}*/}
        {/*  />*/}
        {/*  <Route*/}
        {/*    path=":moduleId"*/}
        {/*    element={<ModuleItem/>}*/}
        {/*    loader={({ params }) => {*/}
        {/*      return fetchArticle(params.moduleId)*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Route>*/}
      </Route>
    )
  );
}


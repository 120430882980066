import classes from "./index.module.scss"
import twelfthOnboard from "../../assets/images/onboard/firstOnboard.png"

const Twelfth = () => {

  return(
    <div className={classes.insideContainer}>
      <img src={twelfthOnboard} alt="twelfth onboard" height="207px" width="207px"/>
      <div className={classes.textDataContainer}>
        <h2 className={classes.header}>Congratulations!</h2>
        <p className={classes.text}>You’ve made it to the Angels round. Your new goal is to get 100 customers.</p>
        <p className={classes.text}>Many tasks are now available to you, but remember: not all of them will take you further on the path to your goal… and some will have nothing to do with your final objective, whatsoever.</p>
      </div>
    </div>
  )
}

export default Twelfth

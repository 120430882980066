import {useState} from "react"

import ninthOnboard from "../../assets/images/onboard/ninthOnboard.png"
import classes from "./index.module.scss"

const Ninth = ({isMobile}) => {
  const [openSelect, setOpenSelect] = useState(false)

  return(
    <div className={classes.insideContainer}>
      <img src={ninthOnboard} alt="ninth onboard" height="207px" width="207px" className={classes.absoluteImage}/>
        <div className={`${classes.textDataContainer} ${isMobile ? "" : classes.withMarginLeft}`}>
          <h2 className={classes.header}>Task completion</h2>
          { !openSelect && <p className={classes.text}>Each task you complete will affect various metrics.</p>}
          { !openSelect && <p className={classes.text}>All tasks have a unique set of directions and information: <br/>
          -a number of turns required to complete a task<br/>
          -a metric that is affected by its successful completion, as well as the magnitude of its change<br/>
          -total human resources required to complete a task.</p>}
          <p className={classes.helperText}>Select a first task impacting the UA metric (a number of users your business has attracted)</p>
        </div>
      {isMobile && !openSelect && <button className={classes.nextButton} onClick={() => setOpenSelect(true)}>
        Try it
      </button>}
    </div>
  )
}

export default Ninth

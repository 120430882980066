import Popup from 'react-modal';
import classes from "./index.module.scss";
import "./modal.css"

const Modal = ({ open, setOpen, onClose, className, classNameOverlay, parentRef = null, cantClose, children }) => {
  const contentStyle = `${classes.popupContent} ${className ? className : ""}`
  const overlayStyle = `${classes.popupOverlay} ${classNameOverlay ? classNameOverlay : ""}`

  return (<Popup
    closeTimeoutMS={200}
    parentSelector={() => parentRef?.current ? parentRef.current : document.body}
    overlayElement={(props, contentElement) => {
      return parentRef?.current ? (
        <div>
          <div{...props}/>
          {contentElement}
        </div>
      ) : (
        <div{...props}>
          {contentElement}
        </div>
      )
    }}
    ariaHideApp={false}
    shouldCloseOnOverlayClick={!cantClose}
    isOpen={open}
    onRequestClose={(e) => (e.keyCode === 27 && cantClose) ? null : onClose ? onClose() : setOpen(false)}
    className={contentStyle}
    overlayClassName={overlayStyle}
    defaultStyles={{backgroundColor: '#fff'}}
  >
    {children}
  </Popup>)
}

export default Modal
